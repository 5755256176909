import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { WindowContext } from "../../context/WindowContext.js";

import TextDisplay from "../TextDisplay/TextDisplay";
import Header from "../Header/Header";

import texts from "../../assets/texts/texts.json";
import "./Content.css";

export default function Content() {

    const { setMenuIsVisible } = useContext(WindowContext);

    const { textId } = useParams();
    const { pathname } = useLocation();
    const [img, setImg] = useState("");
    const [text, setText] = useState("");

    useEffect(() => {
        setImg(texts[textId]["img"]);
        setText(texts[textId]["text"]);
        // getDescription(texts[textId].text)
    }, [textId]);

    const navigate = useNavigate();

    const toHome = () => {
        setMenuIsVisible(true)
        navigate("/");
    }

    const getTitle = (textId) => {
        // const title = text.match(/<h1>(.*)<\/h1>/i);
        // return title[1][0] + title[1].slice(1).toLowerCase();
        const title = textId[0].toUpperCase() + textId.slice(1).replaceAll("-", " ");
        return title
    }

    const getDescription = (text) => {
        const rawText = text.match(/<\/h1>.{0,250}/gi);
        const parsedText = rawText[0].replaceAll(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/gi, " ");
        // console.log(parsedText);
        return parsedText
    }

    return (
        <>
            <Helmet>
                <title>{`${getTitle(textId)} - Pixel16`}</title>
                <meta name="description" content={`${getDescription(texts[textId].text)}...`} />
                <link rel="canonical" href={`https://pixel16${pathname}`} />
            </Helmet>
            <Header isHeroPage={false} />
            <section className="content-section">
                <div className="content-img-wrapper">
                    <img src="https://firebasestorage.googleapis.com/v0/b/pixel16-95875.appspot.com/o/L-Logo.jpg?alt=media&token=583dc792-eb5e-4132-a69e-67c2efc8251f" alt="" onClick={toHome} />
                    <img src={img} alt="" />
                </div>
                <TextDisplay text={text} textId={textId} />
            </section>
        </>

    )
}
