import { useEffect, useRef } from "react";

import "./TextDisplay.css";

export default function TextDisplay({ text, textId }) {

    const textElement = useRef();

    useEffect(() => {
        textElement.current.innerHTML = text;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [text]);

    

    return (
        <div className="text-container" ref={textElement}>
        </div>
    )
}
