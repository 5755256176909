import { useState } from "react"
import "./CookiesBanner.css"
import { useEffect } from "react";

export default function CookiesBanner() {

    const [showBanner, setShowBanner] = useState(true);

    const handleClick = () => {
        if (showBanner) {
            setShowBanner(false);
        }
    }

    // useEffect(() => {
    //     if (acceptedCookies) {
    //         setShowBanner(false);
    //     }
    // }, []);

    return (
        <div className={`cookies-banner-container ${!showBanner ? "hidden-banner" : ""}`}>
            <p>Esta web no utiliza cookies
            </p>
            <button onClick={handleClick}>X</button>
        </div>
    )
}