import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";

import { WindowContextProvider } from "./context/WindowContext";
import { HelmetProvider } from "react-helmet-async";

import Main from './components/Main/Main';
import './App.css';

function App() {

  useEffect(() => {
    const preventContextMenu = (event) => event.preventDefault();
    document.addEventListener("contextmenu", preventContextMenu);
    return () => document.addEventListener("contextmenu", preventContextMenu)
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <WindowContextProvider>
          <Main />
        </WindowContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
