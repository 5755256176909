import { Link } from "react-router-dom";
import Header from "../Header/Header";

import "./NotFound.css"

export default function NotFound() {
    return (
        <>
            <h2 id="not-found-h2">404: Not Found</h2>
            <p id="not-found-p">Sorry, we are not able to find the page you requested... maybe it doesn't exist!</p>
            <div id="not-found-link">
                <Link to={"/"}>Back</Link>
            </div>
        </>
    )
}
