import { useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { WindowContext } from "../../context/WindowContext";

import Hero from "../Hero/Hero";
import Content from "../Content/Content.jsx";
import NotFound from "../NotFound/NotFound";

import "./Main.css";
import CookiesBanner from "../CookiesBanner/CookiesBanner";

export default function Main() {

  const { windowSize, setWindowSize } = useContext(WindowContext);

  useEffect(() => {
    const handleResize = () => setWindowSize([window.innerHeight, window.innerWidth]);
    if (!windowSize.length) {
      setWindowSize([window.innerHeight, window.innerWidth]);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    } else {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }

  }, []);

  return (
    <>
      <main >
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/:textId" element={<Content />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </main>
      <CookiesBanner />
    </>

  )
}
