import { useState, useContext } from "react";
import { WindowContext } from "../../context/WindowContext";

import Header from "../Header/Header";

import "./Hero.css";

export default function Hero() {

    const { windowSize, menuIsVisible, setMenuIsVisible } = useContext(WindowContext);

    const [showMobileMenuFromhero, setShowMobilemenuFromHero] = useState(false);

    const showMenu = () => {
        if (!menuIsVisible) {
            setMenuIsVisible(true);
        }
        if (windowSize[1] < 780) {
            setShowMobilemenuFromHero(true);
        }
    }

    return (
        <>
            <Header menuIsVisible={menuIsVisible} isHeroPage={true} showMobileMenuFromHero={showMobileMenuFromhero} />
            <section className="hero-section" onClick={showMenu}>
                <h1 id="title">Pixel16. Espacio de fotografía</h1>
                {
                    windowSize[1] > 479 ?
                        <>
                            <div className="logo-container">
                                <img src="https://firebasestorage.googleapis.com/v0/b/pixel16-95875.appspot.com/o/L-Logo.jpg?alt=media&token=583dc792-eb5e-4132-a69e-67c2efc8251f" alt="" />
                            </div>
                            <div className="hero-img-container">
                                <img src="https://firebasestorage.googleapis.com/v0/b/pixel16-95875.appspot.com/o/L-Portada.jpg?alt=media&token=2714b5dc-bc97-42f5-8932-4286060dd202" alt="" />
                            </div>
                        </>
                        :
                        <img src="https://firebasestorage.googleapis.com/v0/b/pixel16-95875.appspot.com/o/R-Moviles%20pixel16%20en%202023.jpg?alt=media&token=15a8d267-63b9-47a2-b161-3cf7ab621e37" className="hero-mobile-img" />
                }

            </section>
        </>

    )
}
