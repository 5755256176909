import React, { createContext, useState } from "react";

const WindowContext = createContext();

function WindowContextProvider({ children }) {

    const [windowSize, setWindowSize] = useState([]);
    const [menuIsVisible, setMenuIsVisible] = useState(false);

    return(
        <WindowContext.Provider value={{ windowSize, setWindowSize, menuIsVisible, setMenuIsVisible }}>
            {children}
        </WindowContext.Provider>
    )
}

export {
    WindowContext,
    WindowContextProvider
}